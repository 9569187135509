export default function Faq() {
    return (
        <div
            class="space-y-4 p-14 mt-4 lg:py-28 lg:px-24 section bg-neutral-50"
        >
        <h1 class="text-4xl md:text-5xl font-bold text-gray-900 text-center">
            Frequently Asked Questions
        </h1>
        <p class="text-lg font-medium text-gray-700 pb-6 md:pb-10 text-center">
            Have a question? Check out our FAQs to see if we have an answer for you. If
            you don't see your question here, feel free to contact us!
        </p>
        <details class="group [&amp;_summary::-webkit-details-marker]:hidden">
            <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 class="font-medium">What do you make your websites with?</h2>

            <svg
                class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
                ></path>
            </svg>
            </summary>

            <p class="mt-4 px-4 leading-relaxed text-gray-700">
            We use a variety of tools and technologies to build websites, including
            HTML, CSS, JavaScript, WordPress, Shopify, and more. We choose the best
            tools for each project to ensure that the website meets the client’s needs
            and goals.
            </p>
        </details>

        <details class="group [&amp;_summary::-webkit-details-marker]:hidden">
            <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 class="font-medium">What is SEO?</h2>

            <svg
                class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
                ></path>
            </svg>
            </summary>

            <p class="mt-4 px-4 leading-relaxed text-gray-700">
            SEO, short for Search Engine Optimization, is about improving your
            website's visibility to search engines like Google, Bing, and Yahoo. By
            making adjustments to your site, you can appear at the top of search
            results when people search for relevant keywords. This can attract your
            target audience and lead them to your website, potentially resulting in
            conversions. Contact us today to see how our SEO consultant can help you!
            </p>
        </details>

        <details class="group [&amp;_summary::-webkit-details-marker]:hidden">
            <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 class="font-medium">Isn't SEO dead?</h2>

            <svg
                class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
                ></path>
            </svg>
            </summary>

            <p class="mt-4 px-4 leading-relaxed text-gray-700">
            No, SEO is not dead. In fact, it is more important than ever. With the
            rise of voice search, mobile search, and local search, SEO is crucial for
            businesses to be found online.
            </p>
        </details>

        <details class="group [&amp;_summary::-webkit-details-marker]:hidden">
            <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 class="font-medium">
                My website is slow, can you help me speed it up?
            </h2>

            <svg
                class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
                ></path>
            </svg>
            </summary>

            <p class="mt-4 px-4 leading-relaxed text-gray-700">
            Speed optimization is critical for user experience and search engine
            rankings. We can help you identify the causes of slow loading times on
            your website and implement solutions to improve performance. Contact us
            today to learn more about our website speed optimization services.
            </p>
        </details>

        <details class="group [&amp;_summary::-webkit-details-marker]:hidden">
            <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 class="font-medium">
                What is the difference between a good website and a bad website?
            </h2>

            <svg
                class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
                ></path>
            </svg>
            </summary>

            <p class="mt-4 px-4 leading-relaxed text-gray-700">
            A good website is well-designed, easy to navigate, and provides valuable
            information to visitors. It is optimized for search engines and is
            mobile-friendly. A bad website is poorly designed, difficult to use, and
            lacks valuable content. It may also have slow loading times and be
            difficult to find in search engine results. Contact us today to learn how
            we can help you create a great website that attracts visitors and converts
            them into customers.
            </p>
        </details>
        </div>
    )
}
