export default function Nav() {
    return (
        <nav className=" border-gray-200 text-lg text-black font-medium fixed z-20 flex justify-between items-center px-6 md_s:px-20 w-full shadow-sm">
            <a href="#" className="">
                <img src="/redo.png" className="w-48"></img>
            </a>
            <div className="flex gap-4 items-center">
                <div className="hidden xs:flex gap-4 items-center">
                    <a href="#" className="text-black hover:text-gray-500">
                        Home
                    </a>
                    <a href="#Services" className="text-black hover:text-gray-500">
                        Services
                    </a>
                    <a href="#Pricing" className="text-black hover:text-gray-500">
                        Pricing
                    </a>
                </div>
                <a
                    href="#Contact"
                    className="text-white hover:bg-gray-600 transition duration-300 ease-in-out bg-gray-900 px-4 py-2 rounded-xl"
                >
                    Contact
                </a>
            </div>
        </nav>
    )
}
