import * as d3 from "d3";
import { useRef, useEffect } from "react";
import "../input.css";
import "../output.css";

export default function Hero() {
  const chartRef = useRef(null);

  useEffect(() => {
    const width = 928;
    const height = 500;
    const marginTop = 40;
    const marginRight = 0;
    const marginBottom = 40;
    const marginLeft = 0;

    const stocks = [
      "AAPL",
      "MSFT",
      "GOOGL",
      "AMZN",
      "TSLA",
      "FB",
      "NVDA",
      "NFLX",
      "INTC",
      "AMD",
    ]; // Stock abbreviations
    const n = stocks.length;
    const xz = d3.range(n);
    const yz = d3.range(n).map(() => d3.range(n).map(Math.random));

    const y01z = d3
      .stack()
      .keys(d3.range(n))(d3.transpose(yz))
      .map((data, i) => data.map(([y0, y1]) => [y0, y1, i]));

    const yMax = d3.max(yz, (y) => d3.max(y));
    const y1Max = d3.max(y01z, (y) => d3.max(y, (d) => d[1]));

    const x = d3
      .scaleBand()
      .domain(xz)
      .rangeRound([marginLeft, width - marginRight])
      .padding(0.08);

    const y = d3
      .scaleLinear()
      .domain([0, y1Max])
      .range([height - marginBottom, marginTop]);

    const color = d3
      .scaleSequential(d3.interpolateBlues)
      .domain([-0.5 * n, 1.5 * n]);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("viewBox", [0, 0, width, height])
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto;");

    const rect = svg
      .selectAll("g")
      .data(y01z)
      .join("g")
      .attr("fill", (d, i) => color(i))
      .selectAll("rect")
      .data((d) => d)
      .join("rect")
      .attr("x", (d, i) => x(i))
      .attr("y", height - marginBottom)
      .attr("width", x.bandwidth())
      .attr("height", 0);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(
        d3
          .axisBottom(x)
          .tickSizeOuter(0)
          .tickFormat((i) => stocks[i])
      )
      .selectAll("text")
      .style("font-size", "20px")
      .style("font-weight", "600"); // Use stock abbreviations

    function transitionGrouped() {
      y.domain([0, yMax]);

      rect
        .transition()
        .duration(500)
        .delay((d, i) => i * 20)
        .attr("x", (d, i) => x(i) + (x.bandwidth() / n) * d[2])
        .attr("width", x.bandwidth() / n)
        .transition()
        .attr("y", (d) => y(d[1] - d[0]))
        .attr("height", (d) => y(0) - y(d[1] - d[0]));
    }

    function transitionStacked() {
      y.domain([0, y1Max]);

      rect
        .transition()
        .duration(500)
        .delay((d, i) => i * 20)
        .attr("y", (d) => y(d[1]))
        .attr("height", (d) => y(d[0]) - y(d[1]))
        .transition()
        .attr("x", (d, i) => x(i))
        .attr("width", x.bandwidth());
    }

    function update(layout) {
      if (layout === "stacked") transitionStacked();
      else transitionGrouped();
    }

    // Example usage of update function
    update("stacked");

    // Cleanup function to remove the SVG on unmount
    return () => {
      d3.select(chartRef.current).select("svg").remove();
    };
  }, []);

  const services = [
    {
      title: "Web Development",
      description:
        "We build custom websites and web applications that are tailored to your needs.",
    },
    {
      title: "Mobile Development",
      description:
        "We develop mobile applications for both iOS and Android platforms.",
    },
    {
      title: "Data Science",
      description:
        "We provide data analysis and machine learning services to help you make informed decisions.",
    },
    {
      title: "UI/UX Design",
      description:
        "We design user-friendly interfaces that enhance user experience and drive engagement.",
    },
    {
      title: "Artificial Intelligence",
      description:
        "We offer AI solutions that automate tasks and improve business processes.",
    },
    {
      title: "SEO Management",
      description:
        "We optimize your website to improve its search engine ranking and visibility.",
    },
    {
      title: "Cloud Computing",
      description:
        "We provide cloud solutions that enable you to scale your business and reduce costs.",
    },
    {
      title: "Cybersecurity",
      description:
        "We offer security services to protect your data and systems from cyber threats.",
    },
  ];

  const stats = [
    { id: 1, name: "Conversion Rate Increase", value: "80%" },
    { id: 2, name: "Website Impressions Increase", value: "125%" },
    { id: 3, name: "Uptime guarantee", value: "99.9%" },
    { id: 4, name: "Security Vulnerabilities", value: "0" },
    { id: 5, name: "Projects Made", value: "50+" },
    { id: 6, name: "Size of Staff", value: "8" },
  ];

  return (
    <div className="App">
      <section className="flex flex-col lg:flex-row">
        <div className="flex flex-1 flex-col justify-center items-start mt-40  fade-in px-12 md_s:px-16 xl:px-24">
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-extrabold">
            Blendify
          </h1>
          <p className="text-2xl md:text-2xl lg:text-4xl text-gray-700 italic font-bold mt-2">
            Software - Websites - SEO
          </p>

          <p className="text-lg md:text-xl lg:text-2xl font-medium text-black mt-8">
            Blendify turns businesses into industry leaders with powerful
            <span className="font-black"> software </span> that creates{" "}
            <span className="font-black">growth and success.</span> Partner with
            us to outshine the competition and transform your vision into
            reality.
          </p>

          <div className="flex items-center mt-8 w-full">
            <a
              href="#Contact"
              className="text-lg lg:text-xl cursor-pointer flex gap-3 w-full md:w-auto bg-neutral-900 hover:bg-neutral-700 transition duration-500 ease-in-out flex gap-4 font-bold items-center px-8 py-4 text-white rounded-full"
            >
              Get Started
              <div className="p-2 bg-neutral-700 rounded-full">
                <img
                  src="https://cdn.prod.website-files.com/66477ba6aef90272351460cc/66477ba6aef902723514618f_icon-cta-arrow-white.svg"
                  className="w-4 h-4"
                ></img>
              </div>
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-2 justify-start items-center mt-40 xl:mt-44 xs:mt-40 fade-in px-12 md_s:px-16 xl:px-16 ">
          <p className="font-bold text-black text-center text-2xl md:text-3xl lg:text-4xl fade-in">
            We make software that drives sales.
          </p>

          <p className="text-gray-600 mt-1 mb-4 font-semibold text-center fade-in">
            Statistics about Blendify
          </p>
          <dl className="mt-8 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-8">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
              >
                <dt className="text-lg leading-6 text-gray-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </section>
    </div>
  );
}
