import { useState } from "react";
import { motion } from "framer-motion";

export default function CTA1() {
  const [email, setEmail] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <section
      id="Contact"
      className="w-full px-8 py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-bl from-sky-800 to-sky-500"
    >
      <div className="container mx-auto px-4 md:px-6">
        <div className="grid gap-6 items-center justify-items-center">
          <div className="flex flex-col justify-center space-y-4 text-center">
            <div className="space-y-4">
              <motion.h1
                className="text-4xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Ready to Get Started?
              </motion.h1>
              <motion.p
                className="max-w-[600px] text-zinc-300 md:text-xl mx-auto mt-4 "
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Partner with our expert software development team to bring your
                vision to life.
              </motion.p>
            </div>
            <motion.div
              className="w-full max-w-sm space-y-2 mx-auto"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <form
                action="https://formspree.io/f/xnnqndyl"
                method="POST"
                onSubmit={handleSubmit}
                className="flex flex-col space-y-2 mt-6"
              >
                <motion.div
                  className="relative"
                  initial={false}
                  animate={isFocused ? "focused" : "blurred"}
                  variants={{
                    focused: {
                      scale: 1.05,
                      boxShadow: "0 0 20px rgba(255, 255, 255, 0.5)",
                    },
                    blurred: {
                      scale: 1,
                      boxShadow: "0 0 0px rgba(255, 255, 255, 0)",
                    },
                  }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <motion.input
                    className="w-full px-4 py-2 text-white bg-white/10 rounded-md outline-none placeholder:text-zinc-200"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    required
                    variants={{
                      focused: { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                      blurred: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                    }}
                  />
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-0.5 bg-white"
                    initial={{ scaleX: 0 }}
                    variants={{
                      focused: { scaleX: 1 },
                      blurred: { scaleX: 0 },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-white bg-black rounded-md"
                >
                  Get Started
                </button>
              </form>
              <p className="text-xs text-zinc-200 dark:text-zinc-100">
                Start your journey with us. No commitment required.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
