import * as d3 from "d3";
import { useRef, useEffect } from "react";
// import "./input.css";
import "./output.css";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Pricing from "./components/Pricing";
import CTA1 from "./components/CTA1";
import Faq from "./components/Faq";
import Process from "./components/Process"
import Footer from "./components/Footer"
import HowWeSell from "./components/HowWeSell";
import Testimonials from "./components/Testimonials";
import LogoClouds from "./components/LogoClouds"

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <LogoClouds />
      <HowWeSell />
      <Services />
      <Testimonials />
      <Faq />
      <CTA1 />
      <Footer />
    </div>
  );
}

export default App;
