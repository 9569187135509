export default function HowWeSell() {
  return (
    <div className="bg-white pb-16 pt-24 sm:pb-24 sm:pt-24 xl:pb-24">
      <div className="bg-neutral-900 pb-20 sm:pb-24 xl:pb-0">
        <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
          <div className="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
            <div className="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
              <img
                alt=""
                src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                className="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl"
              />
            </div>
          </div>
          <div className="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-32">
            <figure className="relative isolate">
              <blockquote className="text-5xl font-regular text-white">
                <p>
                  Here is how we{" "}
                  <span className="font-extrabold">build software</span> that{" "}
                  <span className="font-extrabold">drives sales.</span>
                </p>
              </blockquote>
            </figure>
            <div className="flex items-center mt-8 w-full">
              <a
                href="#Contact"
                className="text-lg lg:text-xl cursor-pointer w-full md:w-auto bg-neutral-50 hover:bg-neutral-300 transition duration-500 ease-in-out flex gap-4 font-bold items-center px-6 py-3 text-black rounded-full"
              >
                Book a Call
                <div className="p-2 bg-neutral-400 rounded-full">
                  <img
                    src="https://cdn.prod.website-files.com/66477ba6aef90272351460cc/66477ba6aef902723514618f_icon-cta-arrow-white.svg"
                    className="w-4 h-4"
                  ></img>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
