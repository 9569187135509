const testimonials = [
  {
    body: "Ethan and his team built a typing test for my school. We just reached 100+ active users and 5000+ tests in only a few months. The students love it and have found typing to be fun and engaging. Who knew typing could be so fun? Thanks, Blendify.",
    author: {
      name: "Alexey Davydov, Assistant GM at theCoderSchool",
      imageUrl: "/Alexey.png",
    },
  },
  {
    body: "Thanks so much, Blendify! Our new website is so much cleaner and better organized. We love it.",
    author: {
      name: "Rhea Amante, Owner of Rhea's Beauty",
      imageUrl: "/Rhea.png",
    },
  },
  {
    body: "Ethan and his team boosted our website traffic by 200% in just 3 months. We are thrilled with the results and have seen a significant increase in leads from our site. Highly recommended!",
    author: {
      name: "Jim Flores, Co-Founder of 411 Service Providers",
      imageUrl: "/JimFlores.png",
    },
  },
  {
    body: "We just landed three massive clients thanks to the team. Overall, smooth process and quick communication.",
    author: {
      name: "Austin - Chief Marketing Officer of EnvirokleenUSA",
      imageUrl: "/Austin.png",
    },
  },
  {
    body: "Ethan built a web scraper for us that saved us hours of manual work. He is a web scraping genius!",
    author: {
      name: "Alex Al-Allan, Student and PM at Moraine Valley",
      imageUrl: "/Alex.png",
    },
  },
  {
    body: "Really cool team to work with. Blendify helped me grow my tutoring business at my local college by creating flyers and a website for me. I am often fully booked thanks to them.",
    author: {
      name: "Peter Berardini, Tutor at Santa Barbara Community College",
      imageUrl: "/Peter.png",
    },
  },
];

export default function Testimonials() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Testimonials
          </h2>
          <p className="mt-2 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            We have worked with hundreds of amazing people
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.handle}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      alt=""
                      src={testimonial.author.imageUrl}
                      className="h-10 w-10 rounded-full bg-gray-50"
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
