import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Services() {
  const services = [
    {
      title: "Web Development",
      description:
        "Crafting bespoke websites and web applications that seamlessly blend form and function.",
      image: "/photos/webdev.jpg",
    },
    {
      title: "Mobile Development",
      description:
        "Creating intuitive and powerful mobile experiences that resonate with modern users.",
      image: "/photos/mobile-development.jpg",
    },
    {
      title: "Artificial Intelligence",
      description:
        "Leveraging cutting-edge AI to develop intelligent solutions that drive innovation.",
      image: "/photos/chatGPT.jpg",
    },
    {
      title: "Data Science",
      description:
        "Unlocking the power of data to provide actionable insights and strategic advantage.",
      image:
        "/photos/data-science.png",
    },
    {
      title: "SEO Optimization",
      description:
        "Elevating your digital presence with tailored strategies for maximum visibility.",
      image:
        "/photos/seo.jpg",
    },
    {
      title: "Social Media Marketing",
      description:
        "Engaging your audience and driving growth with targeted social media campaigns.",
      image:
        "/photos/social-media.jpg",
    },
  ];

  return (
    <section className="w-full px-6 py-24 md:py-24 lg:py-24 text-neutral-900 bg-gradient-to-b from-white to-gray-300">
      <div className="container px-4 md:px-6 mx-auto">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center tracking-tight">
          Our Expertise
        </h2>
        <p className="text-lg text-center text-neutral-600 mt-3">
          We offer a wide range of services to help you achieve your goals and
          grow your business.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-16">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
}


function ServiceCard({ service, index }) {
  const [isHovered, setIsHovered] = useState(false);
  const [rotation, setRotation] = useState({ rotateX: 0, rotateY: 0 });

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const { width, height, left, top } = card.getBoundingClientRect();
    const x = e.clientX - left - width / 2;
    const y = e.clientY - top - height / 2;

    const rotateY = (x / width) * 70; // Adjust the divisor to control sensitivity
    const rotateX = (-y / height) * 70; // Negative for correct direction

    setRotation({ rotateX, rotateY });
  };

  return (
    <motion.div

      className="relative group"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.6,
        delay: index * 0.1,
        ease: [0.22, 1, 0.36, 1],
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setRotation({ rotateX: 0, rotateY: 0 }); // Reset rotation on mouse leave
      }}
      onMouseMove={handleMouseMove}
      style={{ perspective: 1000 }}
    >
      <motion.div
        className="overflow-hidden rounded-lg shadow-xl"
        style={{ transformStyle: "preserve-3d" }}
        animate={{
          rotateY: isHovered ? rotation.rotateY : 0,
          rotateX: isHovered ? rotation.rotateX : 0,
        }}
        transition={{ duration: 0.4 }}
      >
        <motion.img
          src={service.image}
          alt={service.title}
          className="w-full h-64 object-cover object-center"
          initial={{ scale: 1.05 }}
          animate={{ scale: isHovered ? 1.1 : 1.05 }}
          transition={{ duration: 0.4 }}
        />
        <div className="p-6 bg-neutral-900 relative">
          <h3 className="text-2xl font-bold mb-2 text-white tracking-wide">
            {service.title}
          </h3>
          <p className="text-gray-300 text-sm leading-relaxed">
            {service.description}
          </p>
          <AnimatePresence>
            {isHovered && (
              <motion.div
                className="absolute inset-0 bg-gradient-to-br from-zinc-900/80 to-zinc-900/20 flex items-center justify-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.div>
  );
}